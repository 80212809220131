.titleBanner {
  display: flex;
  margin: 1rem 0;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--bs-yellow);
    img {
      height: 100%;
      width: auto;
      object-fit: contain;
      max-height: 26px;
      max-width: none;
    }

    &.sb {
      justify-content: space-between;
      .live {
        color: var(--bs-yellow);
        background-color: black;
        font-size: 20px;
        text-transform: uppercase;
        padding: 0 1.5rem;
      }
    }

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      text-transform: uppercase;
      margin-left: 1rem;

      @media screen and (max-width: 992px) {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
}
