.predictionsContainer {
  margin: 0;
  .predictionCol {
    background-color: #eaeaea;
  }
  .predictionFooter {
    background-color: #eaeaea;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      border-radius: 10px;
      margin: 8px 0 8px 0;
      padding: 1.5rem 2.5rem;
      background-color: var(--bs-yellow);
      font-size: 16px;
      font-weight: 700;
    }
  }
}
