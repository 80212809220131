.predictionMatchColContainer {
  background-color: white;
  padding: 0.5rem;
  margin: 15px 0 0px;
  border-radius: 10px;
  min-height: 220px;
  .bannerContainer {
    display: flex !important;
    margin: 0;
    position: relative;
    .competitionBanner {
      position: absolute;
      top: -15px;
      left: -5px;
      padding: 8px;
      background: #3c3c3c;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      min-width: 70%;
      span {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        color: white;
      }
    }
  }
  .mainRow {
    .predicitonInfo {
      height: 100px;
      .date {
        font-size: 12px;
        font-weight: 600;
        color: #6c757d;
      }
      .teamInfo {
        .teamImg {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .teamName {
          color: #6c757d;
          margin-top: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
        }
      }
      .vsContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        .vs {
          font-weight: 600;
          font-size: 20px;
          color: #6c757d;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .predictionQuotesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      padding: 1rem 1.5rem;
      font-size: 16px;
      border-radius: 10px;
      background-color: #6c757d;
      color: white;
    }
  }
}
