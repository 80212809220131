.selector {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4a4a4a;
  position: relative;
  &.optionSelect {
    display: none;

    @media screen and (max-width: 992px) {
      display: flex;
    }
  }

  .container {
    display: block;
    width: 100%;
    border-radius: unset !important;
    padding: 0 !important;
    margin: 0 !important;
    background: unset !important;
  }

  button {
    border: 0;
    width: 100%;
    padding-left: 15px;
    height: 45px;
    text-align: left;
    background: #eaeaea;
    border-radius: 10px;
  }

  button:hover {
    cursor: pointer;
  }

  button::after {
    position: absolute;
    right: 15px;
    top: 13px;
    content: url("https://s3.eu-central-1.wasabisys.com/calcio-com/ui/select-down-arrow.png");
  }

  button.expanded::after {
    content: url("https://s3.eu-central-1.wasabisys.com/calcio-com/ui/select-up-arrow.png");
  }

  .option {
    display: flex;
    gap: 10px;

    img {
      border-radius: 5px;
      max-height: 25px;
      max-width: 18px;
      height: auto;
      width: auto;
    }
  }

  ul.options {
    display: none;
    list-style: none;
  }

  ul.show {
    overflow-y: auto;
    max-height: 300px;
    padding-top: 10px;
    top: 35px;
    display: block;
    z-index: 100;
    position: absolute;
    background: #eaeaea;
    width: 100%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  ul.options li {
    padding-left: 15px;
    height: 45px;
    display: flex;
    align-items: center;
  }

  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover,
  ul.options li[aria-selected="true"] {
    background: #f4c312;
    cursor: pointer;
    color: white;

    &:last-child {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
